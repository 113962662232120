// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
@import "./loader.scss";
@import "./css/status.scss";
@import "~@ctrl/ngx-emoji-mart/picker";
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

$mat-primary: (
  50 : #e0efff,
  100 : #b3d7ff,
  200 : #80bdff,
  300 : #4da3ff,
  400 : #268fff,
  500 : #007bff,
  600 : #0073ff,
  700 : #0068ff,
  800 : #005eff,
  900 : #004bff,
  A100 : #ffffff,
  A200 : #f2f5ff,
  A400 : #bfceff,
  A700 : #a6baff,
  contrast: (50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);

$mat-accent: (
  50 : #f2f1f0,
  100 : #dddcdb,
  200 : #c7c5c3,
  300 : #b1adaa,
  400 : #a09c98,
  500 : #8f8a86,
  600 : #87827e,
  700 : #7c7773,
  800 : #726d69,
  900 : #605a56,
  A100 : #fce1cf,
  A200 : #f8c3a0,
  A400 : #ffa366,
  A700 : #ff944d,
  contrast: (50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #000000,
    500 : #000000,
    600 : #000000,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);

$mat-red: (
  50 : #fce9e9,
  100 : #f7c7c7,
  200 : #f2a2a2,
  300 : #ec7d7d,
  400 : #e86161,
  500 : #e44545,
  600 : #e13e3e,
  700 : #dd3636,
  800 : #d92e2e,
  900 : #d11f1f,
  A100 : #ffffff,
  A200 : #ffd5d5,
  A400 : #ffa2a2,
  A700 : #ff8989,
  contrast: (50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #000000,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);

$my-primary: mat.define-palette($mat-primary, 500);
$my-accent: mat.define-palette($mat-accent, 500);

// The "warn" palette is optional and defaults to red if not specified.
$my-warn: mat.define-palette($mat-red);

$my-theme: mat.define-light-theme((color: (primary: $my-primary,
        accent: $my-accent,
        warn: $my-warn,
      )));

@include mat.core-theme($my-theme);
@include mat.all-component-themes($my-theme);

.mat-success {
  background: #28a745;
  color: #fff;
}

.mat-orange {
  background: orange;
  color: #000;
}

.aligned-with-icon {
  position: absolute;
  margin-top: 0px;
  margin-left: 5px;
  /* optional */
}

/* You can add global styles to this file, and also import other style files */
html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-red {
  background: #b71c1c;
  color: #fff;
}

.mat-green {
  background: #4BB543;
  color: #fff;
}

.mat-white circle {
  color: #fff;
  stroke: #fff;
}

.big-spacer {
  flex: 1 1 auto;
}

.mat-error {
  margin-bottom: 5px;
}

.mat-gray {
  background: #36393d;
  color: #fff;
}

.mat-tab-label {
  color: #fff !important;
}

.full-width {
  width: 100%;
}

app-organization-user .mat-form-field-appearance-legacy .mat-form-field-label,
app-standard-user .mat-form-field-appearance-legacy .mat-form-field-label {
  color: #fff;
}

.login-spinner {
  position: fixed !important;
  top: 0px;
  left: 0px;
  right: 0px;
  opacity: 0.8;
  z-index: 1000;
}

.mat-nav-list .active {
  background: rgba(0, 0, 0, 0.04);
}

.timepicker-overlay,
.timepicker-backdrop-overlay {
  z-index: 999999 !important;
}

.timepicker__header {
  background: #b71c1c !important;
}

/* Importing Bootstrap SCSS file. */
@import '~bootstrap/scss/bootstrap';

#backButton {
  position: fixed;
  bottom: 30px;
  left: 30px;
  z-index: 1000;
}